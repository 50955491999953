import React from "react";
import "./ivfPage.css";

import { Helmet } from "react-helmet";
export default function ChaitraThankYou() {
  return (
    <>
      <Helmet>

        {/* latest */}
        <script>
  {`gtag('event', 'conversion', {'send_to': 'AW-11221938845/vy_BCK-NoMcYEJ3lhOcp'});`}
</script>
{/*  */}

        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-11045475704/P7FaCK6wwpwYEPiq8pIp'})`}
        </script>
      </Helmet>
      <header class="header_main_container">
        <section class="header_main_width">
          <div class="header_logo">
            <img src="https://ovum-hospital.web.app/static/media/ovum-logo.6b08910b0e155f6fa04e.png" />
          </div>
          <div class="header_appiontment">
            <section class="header_appiontent_items">
              <div>
                <a href="tel:6364943214">
                  <i class="fa-solid fa-square-phone"></i> +91 6364943214
                </a>
              </div>
              <div>
                <a class="a-btn" href="#headerform">
                  <i class="fa-solid fa-calendar-check"></i>Book Appiontment
                </a>
              </div>
            </section>
          </div>
        </section>
      </header>
      <section>
        <main class="thank-you-page">
          <h1>Thank you For your time</h1>
          <a href="https://ovumhospitals.com/ivf-dr-chaithra">
            <button>Go back</button>
          </a>
        </main>
      </section>
      <footer class="footer-main">
        <div class="footer-logo">
          <img src={require("./assets/img/logo1.png")} />
        </div>
        <div class="footer-brnches">
          <p>Banashankari | Kalyan Nagar | Hennur | Hoskote | Bhattarahalli</p>
        </div>
        <p>Copyright © ovumhospitals.com, All rights reserved</p>
      </footer>
    </>
  );
}
