import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import logo from "../../Assets/ovum-logo.png";

import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillTwitterCircle,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import specialitiesData from "../../Assets/Data/Specialities/speciality.json";
const Index = () => {
  return (
    <>
      {/* <div className={classes.footerMain}>
      <div className={classes.logomain}>
        <div>
          <img src="https://ovumhospitals.com/wp-content/uploads/2019/11/logo1.png" />
        </div>
        <div>
          <p>
            Ovum is a mother and child care hospital in Bangalore and Mysore. We
            are supported with top-rated doctors and equipped with advanced
            infrastructure.
          </p>
        </div>
        <div>
            <span>Trusted by 20000+ Families</span>
        </div>
        <div className={classes.socialmedia}>
            <div>
            <BsFacebook size={20}/>
            </div>
            <div>
                <AiFillInstagram size={25}/>
            </div>
            <div>
                <AiFillTwitterCircle size={25}/>
            </div>
            <div>
            </div>
        </div>
      </div>
      <div className={classes.importntLinksmain}>
        <div>
            <h3>Important Links</h3>
        </div>
        <div className={classes.importntLinks}>
        <Link>Home</Link>
        <Link>About</Link>
        <Link>Facilities</Link>
        <Link>Gallery</Link>
        <Link>KATS</Link>
        <Link>Blogs</Link>
        <Link>FAQs</Link>
        <Link>Careers</Link>
        <Link>Sitemap</Link>
        </div>
      </div>
      <div className={classes.servicesmain}>
        <div>
            <h3>Services</h3>
        </div>
        <div className={classes.services}>
        <Link>Fertility Treatment in Bangalore</Link>
        <Link>Fetal Medicine</Link>
        <Link>Infant Care & Paediatrics Hospital in Bangalore</Link>
        <Link>Best NICU Hospital in Bangalore</Link>
        <Link>Paediatric HDU</Link>
        <Link>Blogs</Link>
        <Link>Best Gynecology Clinic in Bangalore</Link>
        <Link>Child Development Clinic</Link>
        </div>
      </div>
    </div>
    <div className={classes.copyrights}>
    © Copyright 2022. All Rights Reserved.
    </div> */}

      <div className={styles.footerAlphaMain}>
        <div className={styles.footerMain}>
          <div className={styles.footerSection}>
            <div className={styles.logoContent}>
              <a href="https://ovumhospitals.com/">
                <img
                  alt="Ovum Hospitals - Footer Logo"
                  class=""
                  src="https://ik.imagekit.io/d7t01fhx2/ovum-logo-new-(1).png?updatedAt=1693463514059"
                />
              </a>
              {/* <img src="https://ovumhospitals.com/wp-content/uploads/2019/11/logo1.png" /> */}
              {/* <img src="https://ik.imagekit.io/d7t01fhx2/Logo.jpg?updatedAt=1683193847900" /> */}
            </div>
            <p>
              The hospital has a large team of doctors composed of specialists
              in different areas such as neonatology, obstetrics, gynecology,
              and general medicine to ensure that patient care is well-rounded
              and there are no lapses in their treatment.
            </p>
            <div className={styles.socialMedia}>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/ovumhospitals"
                    target="_blank"
                  >
                    <AiFillFacebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/ovum_hospitals/"
                    target="_blank"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/ovum-hospitals---india/"
                    target="_blank"
                  >
                    <AiFillLinkedin />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/OVUMHospitals" target="_blank">
                    <AiFillTwitterCircle />
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.youtube.com/@ovumhospitals220"
                    target="_blank"
                  >
                    <AiFillYoutube />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* //boxes  */}
          <div>
            <h2>About us</h2>
            <ul>
              <li>
                <Link to="/"> Home </Link>
              </li>
              <li>
                <Link to="/about-us"> Who we are </Link>
              </li>
              <li>
                <Link to="/about-us/why-ovum"> Why ovum </Link>
              </li>
              <li>
                <Link to="/our-facilities"> Facilities </Link>
              </li>
              <li>
                <Link to="/"> Academics </Link>
              </li>
              <li>
                <Link to="/careers"> Careers </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>Important Information </h2>
            <ul>
              <li>
                <Link to="/blog"> Blogs </Link>
              </li>
              <li>
                <Link to="/contact"> Contact us </Link>
              </li>
              <li>
                <Link to="/media/faq"> FAQ </Link>
              </li>
              {/* <li>
                <Link to="/"> Testimonial </Link>
              </li> */}
              <li>
                <Link to="/media/doctors-talk"> Doctors Talk </Link>
              </li>
              <li>
                <Link to="/media/patient-testimonials">
                  {" "}
                  Patient Testimonials{" "}
                </Link>
              </li>
              <li>
                <Link to="/media/gallery"> Gallery </Link>
              </li>
              <li>
                <Link to="/a-z"> A to Z </Link>
              </li>
            </ul>
          </div>
          <div>
            <h2>Services </h2>
            <ul className={styles.footerSpecialityList}>
              {specialitiesData.map((item) => {
                return (
                  <li key={item.id}>
                    <Link to={`/our-specialities/${item.specialityLink}`}>
                      {item.specialityTitle}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.copyRights}>
        <div className={styles.footerLink}>
          <p> © Copyright 2024. All Rights Reserved.</p>
          <p>
            Designed & Developed By{" "}
            <span>
              <a href="https://digikit.in/" target="_blank">
                Digikit
              </a>
            </span>
          </p>
        </div>
      </div>
    </>
  );
};
export default Index;
