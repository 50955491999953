import React, { useRef } from "react";
import "./sidenav.css";
import { IoCallSharp } from "react-icons/io5";
import { BsWhatsapp } from "react-icons/bs";
import { BsFillCartDashFill } from "react-icons/bs";
export default function Index() {
  let ref = useRef();

  return (
    <>
      <div id="mysidenav">
        <div id="sidenavappointment" ref={ref}>
          <a
            href="https://api.whatsapp.com/send/?phone=9741591777&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <BsWhatsapp />
            Book Appointment
          </a>
        </div>
        <div id="sidenavphone">
          <a href="tel:+918046253900" target="_blank">
            <IoCallSharp />
            Call Us
          </a>
        </div>
        <div id="cartItem">
          <div id="new-icon">
            <div id="icon">NEW</div>
          </div>
          <a href="https://ovumbabybuddy.com/" target="_blank">
            <BsFillCartDashFill />
            Babybuddy
          </a>
        </div>
      </div>
    </>
  );
}
