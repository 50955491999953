import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
//browser router
import { useNavigate } from "react-router-dom";

export default function Redirection() {
  const { location } = useLocation();
  const { pathname } = window.location;

  const RedirectionArray = [
    {
      link: ["/bhattarahalli", "/centers/bhattarahalli/5"],
      redirect: "/centers/bhattarahalli",
    },
    {
      link: ["/hennur", "/hennur/hennur-2", "/centers/hennur/4"],
      redirect: "/centers/hennur",
    },
    {
      link: ["/kalyannagar", "/centers/kalyan-nagar/1"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: ["/hoskote", "/centers/hoskote/2"],
      redirect: "/centers/hoskote",
    },
    {
      link: ["/banashankari", "/centers/banashankari/3"],
      redirect: "/centers/banashankari",
    },
    {
      link: ["/bhattarahalli-doctors"],
      redirect: "/doctors/bhattarahalli",
    },
    {
      link: ["/alldoctors"],
      redirect: "/doctors/banashankari",
    },
    {
      link: ["/hennur-doctor"],
      redirect: "/doctors/hennur",
    },
    {
      link: ["/hoskote-doctors"],
      redirect: "/doctors/hoskote",
    },
    {
      link: ["/kalyannagar-doctors"],
      redirect: "/doctors/kalyan-nagar",
    },
    {
      link: ["/lactation"],
      redirect: "/our-specialities/lactation",
    },

    {
      link: ["/location/all-locations"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: ["/location/banashankari"],
      redirect: "/centers/banashankari",
    },
    {
      link: ["/location/hennur"],
      redirect: "/centers/hennur",
    },
    {
      link: ["/location/hoskote"],
      redirect: "/centers/hoskote",
    },
    {
      link: ["/location/kalyannagar"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: [
        "/vision-mission-values",
        "/who-we-are",
        "/who-we-are",
        "/why-ovum",
      ],
      redirect: "/about-us",
    },
    {
      link: ["/services/nutrition"],
      redirect: "/our-specialities/dietics",
    },
    {
      link: ["/services/maternal-health-care"],
      redirect: "/our-specialities/fetal-medicine",
    },
    {
      link: ["/services/general-pediatrics"],
      redirect: "/our-specialities/general-medicine-and-diabetology",
    },
    {
      link: ["/services/fertility"],
      redirect: "/our-specialities/ivf",
    },
    {
      link: ["/services/laboratory-and-pharmacy"],
      redirect: "/our-specialities/lab-and-pharmacy",
    },
    {
      link: ["/services/breastfeeding-support"],
      redirect: "/our-specialities/lactation",
    },
    {
      link: ["/services/neonatal-icu"],
      redirect: "/our-specialities/nicu",
    },
    {
      link: ["/services/women-health"],
      redirect: "/our-specialities/obstetrics-and-gynecology",
    },
    {
      link: ["/services/physiotheraphy"],
      redirect: "/our-specialities/physiotherapy",
    },
    {
      link: ["/services/ultrasonography"],
      redirect: "/our-specialities/ultrasonography",
    },
    {
      link: ["/allcenters"],
      redirect: "/centers/kalyan-nagar",
    },
    {
      link: ["/video-testimonials"],
      redirect: "/media/patient-testimonials",
    },
  ];

  function removeSlash(link) {
    if (link.slice(-1) === "/") {
      return link.slice(0, -1);
    } else {
      return link;
    }
  }

  useEffect(() => {
    RedirectionArray.map((item) => {
      let WebLink = removeSlash(window.location.pathname);
      if (item.link.includes(WebLink)) {
        window.location.href = item.redirect;
      }
    });
  }, [location, pathname]);
}
