import React from "react";
import { useEffect } from "react";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet } from "react-helmet";

import "./ivfPage.css";
export default function IvfDrChaithra() {
  useEffect(() => {
    let fact_card_height = document.querySelector(".facts_cards").offsetHeight;
    let fact_card_content =
      document.querySelector(".facts_content").offsetHeight;

    document.querySelector(".facts_items").style.height = `${
      fact_card_content + fact_card_height - 50
    }px`;

    document.querySelector(".facts_cards").style.top = `-${
      fact_card_height / 2
    }px`;

    document.querySelector(".facts_content").style.bottom = `-${
      fact_card_content / 2
    }px`;
  }, []);

  async function callApi(name, email, phone, hospital) {
    var requestOptions = {
      mode: "no-cors",
    };
    const data = fetch(
      `https://ovummaternity.teknovatecrm.in/en_IN/lead?name=${name}&mobile=+91${phone}&email=${email}&note=${hospital}&city=${hospital}&source=8&company=1`,
      requestOptions
    );
  }
  // callApi();

  function handleSubmit(e) {
    e.preventDefault();

    let form = document.forms["first_form"];

    let name = form.elements["name"].value;
    let email = form.elements["email"].value;
    let phone = form.elements["phone"].value;
    let hospital = form.elements["hospital-name"].value;
    console.log(name, email, phone, hospital);
    // callApi(name, email, phone, hospital);
    form.submit();
  }

  return (
    <>
      {" "}
      <Helmet>

{/* latest tag */}
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11221938845"></script>
<script>
{`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date());

  gtag('config', 'AW-11221938845');`}
</script>
{/*  */}

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-CW8MTK238W"
        />
        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-11045475704/P7FaCK6wwpwYEPiq8pIp'})`}
        </script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'G-CW8MTK238W');`}
        </script>
      </Helmet>
      <header className="header_main_container">
        <section className="header_main_width">
          <div className="header_logo">
            <img src="https://ovum-hospital.web.app/static/media/ovum-logo.6b08910b0e155f6fa04e.png" />
          </div>
          <div className="header_appiontment">
            <section className="header_appiontent_items">
              <div>
                <a href="tel:6364462133">
                  <i className="fa-solid fa-square-phone"></i> +91 6364462133
                </a>
              </div>
              <div>
                <a className="a-btn" href="">
                  <i className="fa-solid fa-calendar-check"></i>Book Appiontment
                </a>
              </div>
            </section>
          </div>
        </section>
      </header>
      {/* /// header end   */}
      <section className="banner_container">
        <div className="banner_width_items">
          <div className="banner_img"></div>
          <div className="banner_form">
            <form
              className="form_main_container"
              method="POST"
              action="https://digikit.in/forms/ovum-hospital/ivf-dr-chaithra/save.php"
              onSubmit={handleSubmit}
              id="first_form"
              name="first_form"
            >
              <h3>Book Free Consultation</h3>
              <div>
                <i className="fa-solid fa-circle-user"></i>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  placeholder="Your Name"
                />
              </div>
              <div>
                <i className="fa-solid fa-envelope"></i>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  placeholder="Your Email"
                />
              </div>

              <div>
                <i className="fa-solid fa-square-phone"></i>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  pattern="^[6-9]\d{9}$"
                  required
                  placeholder="Your Phone NUmber"
                />
              </div>
              <div>
                <i className="fa-solid fa-hospital"></i>
                <select
                  id="hospital-name"
                  name="hospital-name"
                  className="select-branches"
                  required
                >
                  <option value="" disabled selected>
                    Choose your Branch
                  </option>
                  {/* <option value="Banashankari">Banashankari</option> */}
                  <option value="hoskote">Hoskote</option>
                  <option value="kalyan-nagar">Kalyan Nagar</option>
                  <option value="hennuru">Hennur</option>
                  <option value="bhattarahalli">Bhattarahalli</option>
                </select>
              </div>
              <input
                type="hidden"
                value={window.location.href}
                name="urlValue"
              />
              <div>
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </section>
      {/* <!--Banner End--> */}
      <section className="why_container">
        <section className="why_width_container">
          <h3>What makes us the best choice?</h3>
          <section className="why_items">
            <div>
              <img
                src={require("./assets/img/icons/ExpertIVFpractitioners.png")}
              />
              <p>Expert IVF practitioners</p>
            </div>
            <div>
              <img
                src={require("./assets/img/icons/BudgetFriendlyoptions.png")}
              />
              <p>Budget Friendly options</p>
            </div>
            <div>
              <img src={require("./assets/img/icons/Highsuccessrate.png")} />
              <p>High success rate</p>
            </div>
            <div>
              <img src={require("./assets/img/icons/personalisedcare.png")} />
              <p>Personalised care</p>
            </div>
            <div>
              <img src={require("./assets/img/icons/Advancedtechnology.png")} />
              <p>Advanced technology</p>
            </div>
          </section>
        </section>
      </section>
      <section className="facts_main_container">
        <section className="facts_width">
          <section className="facts_items_position">
            <section className="facts_items">
              <section className="facts_cards"></section>

              <section className="facts_content">
                <h1>OVUM Hospital #1 IVF Center in Bangalore</h1>

                <p>
                  Every person has the right to experience the joys of
                  parenthood. We offer the highest care to support you
                  throughout your fertility journey. With our highly skilled
                  fertility specialists, trained embryologists, counsellors and
                  fertility-trained nurses, we work tirelessly to help you
                  achieve your dream of having a family.
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
      {/* <!--Why Ovum End--> */}
      <section className="doctorscontiner">
        <h1> OUR IVF SPECIALIST</h1>
        <main className="ivfdoctorsingle">
          <div>
            <img src={require("./assets/img/doctorsinglea.jpg")} />
          </div>
          <div>
            <h2>Dr Chaithra S. K</h2>
            <h6>Obstetrician & Gynecologist</h6>
            <div>
              <span>
                Qualification : MBBS, MS OBG, FRM, DRM , Infertility Specialist
              </span>
              <br />
              <span>Work Experience : 13 Years of Experience</span>
            </div>
            <br />
            <div>
              <h6>About Doctor</h6>
              <p>
                Dr Chaithra S. K. is an Infertility specialist at Ovum Hospital
                in Bangalore. She has received multiple awards and recognition
                for her skills and knowledge in infertility. She has treated
                over 6000 couples and performed over 1000 IVF/ICSI cycles and
                Endoscopy procedures with an unprecedented success rate of over
                65%. With expertise in reproductive genetics, endoscopy,
                andrology, and fertility preservation, Dr Chaithra offers a
                holistic and integrative approach to fertility treatment with
                the latest medical technology.
              </p>
            </div>
          </div>
          <div></div>
          {/* <article className="doctorsingle">
            <div className="doctorimage1">
              <img src="https://cdn-doopl.nitrocdn.com/ThweVEdLHoiGldLbxJTyPcNIKzWqBzkt/assets/images/optimized/rev-ffb4984/wp-content/uploads/2022/02/Dr-Prakrutha.jpg" />
            </div>
            <div>
              <span>Prakrutha S</span>
              <h3>MBBS, MS , FIRM</h3>
              <p>6+ Years Experience</p>
            </div>
          </article>
          <article className="doctorsingle">
            <div className="doctorimage1">
              <img src="https://cdn-doopl.nitrocdn.com/ThweVEdLHoiGldLbxJTyPcNIKzWqBzkt/assets/images/optimized/rev-ffb4984/wp-content/uploads/2022/02/Dr-Archana.jpg" />
            </div>
            <div>
              <span>Archana Karthik</span>
              <h3>MBBS, DGO, DNB, FICOG, FRM.</h3>
              <p>10+ Years Experience</p>
            </div>
          </article> */}
        </main>

        <div className="buttondiv" style={{ paddingBottom: "25px" }}>
          <button className="buttona">
            <a href="">Consult our Doctor</a>
          </button>
        </div>
      </section>
      {/* <!--our specialist end--> */}
      <section className="why_choose_container">
        <section className="why_choose_items">
          <div>
            <img
              width="100%"
              className="verticlabaannerdoctor"
              src={require("./assets/img/modeldoctor.png")}
            />
          </div>
          <div>
            <h1>Why Choose Ovum Hospital</h1>
            <p>
              Ovum hospital provides a diverse range of fertility treatments
              with a focus on quality care. As one of the top IVF treatment
              centers in Bangalore, we have advanced laboratories and a team of
              highly qualified and experienced fertility specialists who
              collaborate with patients to provide personalized treatment plans
              that enhance the likelihood of conception.
            </p>
            <br />
            <p>
              <strong>Fertility services offered at Ovum</strong>
            </p>
            <ul>
              <li>IUI - Intrauterine insemination.</li>
              <li>
                IVF | ICSI - In vitro fertilization and intracytoplasmic sperm
                injection.
              </li>
              <li>ERA test - Endometrial receptivity array.</li>
              <li>PGT - Preimplantation genetic testing.</li>
              <li>Donor programs (Sperm and egg).</li>
              <li>Surrogacy services.</li>
              <li>
                Fertility-enhancing hysteroscopy and laparoscopy surgeries.
              </li>
              <li>Ultrasonography - Advanced.</li>
              <li>Treatment for repeated IVF failures & Abortions.</li>
              <li>Regenerative medicine (Endometrium & Ovaries).</li>
              <li>Male infertility treatment (TESA / PESA).</li>
            </ul>
            <section className="why_appiontent_items">
              <div>
                <a href="+6364462133">
                  <i className="fa-solid fa-square-phone"></i> +91 6364462133
                </a>
              </div>
              <div>
                <a className="a-btn-new" href="">
                  <i className="fa-solid fa-calendar-check"></i>Book Appiontment
                </a>
              </div>
            </section>

            <section className="why_choose_item"></section>
          </div>
        </section>
      </section>
      <section>
        <main className="gallery-heading">
          <h1>Gallery</h1>
          <h5>Ovum Hospitals, Bengaluru</h5>
        </main>

        {/* <div className="swiper mySwiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/kotanur.jpg")}
                alt=""
              />
            </div>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/239A6561.44123386.jpg")}
                alt=""
              />
            </div>
            <div className="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/239A6827.a1f7c348.jpg")}
                alt=""
              />
            </div>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/banass.jpg")}
                alt=""
              />
            </div>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/meternity.jpg")}
                alt=""
              />
            </div>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/single_room_thumb.jpg")}
                alt=""
              />
            </div>
          </div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
        </div> */}
        <Swiper
          slidesPerView={1}
          spaceBetween={25}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
          }}
          modules={[Navigation]}
          className="swiper-wrapper"
        >
          <SwiperSlide>
            <div className="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/kotanur.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/239A6561.44123386.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <img
                width="103px"
                src={require("./assets/img/icons/gallery/239A6827.a1f7c348.jpg")}
                alt=""
              />
            </div>{" "}
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/banass.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/meternity.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <img
                width="10%"
                src={require("./assets/img/icons/gallery/single_room_thumb.jpg")}
                alt=""
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="testimonial_container">
        <div className="buttondiv">
          <button className="buttona">
            {" "}
            <a href="">Consult our Doctors</a>
          </button>
        </div>
      </section>
      {/* <section className="feturecontent">
        <article>
          <h1> Service we offered</h1>
        </article>
        <main>
          <ul className="featurelist">
            <li>Expert Fertility Evaluation</li>
            <li>Fertility Correction Interventions (medical & Surgical)</li>
            <li>Andrology</li>
            <li>Male Erectile Dysfunction Treatment</li>
            <li>Intra Uterine Insemination (IUI)</li>
            <li>In Vitro Fertilisation (IVF)</li>
            <li>Intracytoplasmic sperm injection (ICSI)</li>
            <li>Cryopreservation/Freezing (Sperm/Egg/Embryo)</li>
            <li>Third-party Reproduction</li>
            <li>Professional Counselling</li>
          </ul>
        </main>
      </section> */}
      <div className="mobile-bottom-container">
        <div className="mobile-bottom">
          <div className="mobile-bottom-1 bottomwhatsapp">
            <div className="mobile-bottom-img">
              <i className="fa-regular fa-calendar-check"></i>
            </div>
            <div className="mobile-bottom-text">
              <a href="tel:+916364462133"> Book Appointment</a>
            </div>
          </div>
          <div className="mobile-bottom-1 bottomcall">
            <div className="mobile-bottom-img">
              <i className="fa-solid fa-phone"></i>
            </div>
            <div className="mobile-bottom-text">
              <a href="tel:+916364462133"> Call Us</a>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer_main_container">
        <section className="footer_width_container">
          <section className="footer_items">
            <div className="footer_logo_container">
              <img src={require("./assets/img/logo1.png")} />
              <div className="footer_social_media"></div>
            </div>
            <div className="footer_contents">
              <a className="footer_link_a" href="">
                Contact Us{" "}
              </a>
              <p>
                <a href="tel:6364462133" className="footer_call">
                  <i className="fa-solid fa-phone"></i> +91 6364462133
                </a>
              </p>
              <address>Kalyan Nagar | Hennur | Hoskote | Bhattarahalli</address>
            </div>
          </section>
        </section>
      </footer>{" "}
      <p style={{ textAlign: "center" }}>
        Copyright © ovumhospitals.com, All rights reserved
      </p>
    </>
  );
}
